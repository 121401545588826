import React, { useContext } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { KnowledgeProvider } from "./context/knowledge.context";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import Login from "./Login";
import { AuthContext, AuthProvider } from "./context/auth.context";
import { UserProvider } from "./context/user.context";

const CheckAuthentication = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const isAuth = isAuthenticated();

    if (!isAuth) {
        // User not authenticated, render login component
        return <Login />;
    }

    // User authenticated, render the rest of the app
    return (
        <KnowledgeProvider>
            <BrowserRouter>
                <DevSupport
                    ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
                >
                    <App />
                </DevSupport>
            </BrowserRouter>
        </KnowledgeProvider>
    );
};

const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <UserProvider>
                <CheckAuthentication />
            </UserProvider>
        </AuthProvider>
    </React.StrictMode>
);
