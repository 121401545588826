import Typography from "@mui/material/Typography";
import {Badge, Divider, List, ListItem, ListItemButton, ListItemText, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import LinkIcon from '@mui/icons-material/Link';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const SourceList = ({paragraphs, handleSourceClick}) => {

    const [pdfs, setPDFs] = useState([]);
    const [urls, setURLs] = useState([]);

    useEffect(() => {
        setPDFs(paragraphs.filter(obj => obj.sourceType === 'pdf'))
        setURLs(paragraphs.filter(obj => obj.sourceType === 'url'))
    }, [paragraphs]);

    return (
        <Box
            bgcolor='#FFF'
            sx={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                height: 'calc(100vh - 76px)',
                maxHeight: 'calc(100vh - 76px)',
                width: 300,
                py: 1
            }}
        >
            <Typography
                textAlign='center'
                color='primary'
                fontWeight='bold'
                mb={6}
                fontSize={18}
            >
                Quellen
            </Typography>
            <Box
                sx={{
                    px: 3,
                    display: 'flex',
                    mb: 1,
                }}
            >
                <LinkIcon
                    color='primary'
                />
                <Typography
                    color='primary'
                    fontWeight='bold'
                    pl={2}
                >
                    Links
                </Typography>
            </Box>
            <Divider
                sx={{
                    backgroundColor: 'rgba(226, 86, 38, 0.3)',
                    mb: 3,
                }}
            />
            <Box
                sx={{
                    px: 3,
                    mb: 10,
                    maxHeight: '35%',
                    overflowY: 'auto',
                }}
            >
                {urls.map((item, index) =>(
                    <Box
                        key={index}
                        sx={{
                            width: '100%',
                            height: 40,
                            backgroundColor: '#EDEDED',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            pl: 3,
                            mb: 2,
                            '&:hover': {
                                cursor: 'pointer'
                            },
                        }}
                        onClick={() => handleSourceClick(item)}
                    >
                        <Tooltip title={item.name}>
                            <Typography
                                fontSize={14}
                                noWrap={true}
                            >
                                {item.name}
                            </Typography>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    px: 3,
                    display: 'flex',
                    mb: 1,
                }}
            >
                <DescriptionOutlinedIcon
                    color='primary'
                />
                <Typography
                    color='primary'
                    fontWeight='bold'
                    pl={2}
                >
                    PDFs
                </Typography>
            </Box>
            <Divider
                sx={{
                    backgroundColor: 'rgba(226, 86, 38, 0.3)',
                    mb: 3,
                }}
            />
            <Box
                sx={{
                    px: 3,
                    mb: 10,
                    maxHeight: '35%',
                    overflowY: 'auto'
                }}
            >
                {pdfs.map((item, index) =>(
                    <Box
                        key={index}
                        sx={{
                            width: '100%',
                            height: 40,
                            backgroundColor: '#EDEDED',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            pl: 3,
                            mb: 2,
                            '&:hover': {
                                cursor: 'pointer'
                            },
                        }}
                        onClick={() => handleSourceClick(item)}
                    >
                        <Tooltip title={item.name}>
                            <Typography
                                fontSize={14}
                                noWrap={true}
                            >
                                {item.name}
                            </Typography>
                        </Tooltip>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default SourceList


{/*

<List
                sx={{
                    overflowY: 'auto',
                    maxHeight: '50vh' ,
                    borderRadius: 4,
                    py: 0,
                    boxShadow: 5,
                    scrollbarWidth: 'none', // Entfernt die Scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Entfernt die Scrollbar in Webkit-Browsern (Chrome, Safari, Edge)
                    },
                }}>
                {paragraphs.map((item, index) =>(
                    <>
                        <ListItemButton
                            key={index}
                            onClick={() => handleSourceClick(item)}
                            sx={{background: 'white'}}
                        >
                            <ListItem key={index} color={'white'} sx={{flexDirection: 'row'}}>
                                <ListItemText>
                                    <Typography > {item.source.split('?')[0].replace(/-+$/, ' ')}</Typography>
                                </ListItemText>
                            </ListItem>
                        </ListItemButton>
                        { index !== paragraphs.length -1 ? <Divider/>: <></>}
                    </>
                ))}
            </List>

*/}
