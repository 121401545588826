import { useContext } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import { AuthContext } from "../context/auth.context";
import Logo from "../icons/saige_Logo_klein.png";
import IconButton from "@mui/material/IconButton";
import {LogoutOutlined} from "@mui/icons-material";



const ResponsiveAppBar = () => {
    const { logout} = useContext(AuthContext);
     const handleLogout = () => {
        logout();
      };

    return (
            <Box
                sx={{
                    width: '100%',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: '60px',
                    pr: '10px',
                }}
            >
                {/* Logo and application name <img src={Logo} style={{ maxWidth: '100%', maxHeight: 30}} alt="Logo"/> */}
                <img src={Logo} style={{ maxWidth: '100%', maxHeight: 30}} alt="Logo"/>
                <IconButton
                    onClick={() => handleLogout()}
                >
                    <LogoutOutlined
                        color='black'
                    />
                </IconButton>
            </Box>
    );
};
export default ResponsiveAppBar;
