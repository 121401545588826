import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useContext, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axiosInstance from "../../axiosInstance";
import {KnowledgeContext} from "../../context/knowledge.context";

const DeleteKnowledgeBaseModal = ({openDeleteKnowledgeBaseModal, handleDeleteKnowledgeBaseModalClose, selectedKnowledgeBase}) => {

    const [knowledgeBaseToDelete, setKnowledgeBaseToDelete] = useState({})

    const SERVER_HOST = window.config.apiUrl;

    const {deleteKnowledgeBase} = useContext(KnowledgeContext)

    useEffect(() => {
        setKnowledgeBaseToDelete(selectedKnowledgeBase)
    }, [selectedKnowledgeBase]);

    const onDeleteButtonClick = () => {

        axiosInstance.request({
            url: `${SERVER_HOST}/topics/${knowledgeBaseToDelete.id}`,
            method: 'delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(res => {
            handleDeleteKnowledgeBaseModalClose()
            deleteKnowledgeBase(selectedKnowledgeBase)
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <Modal
            open={openDeleteKnowledgeBaseModal}
            onClose={handleDeleteKnowledgeBaseModalClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 5,
            }}>
                <Box sx={{mb: 3}}>
                    <Typography
                        fontWeight="bold">
                        Möchten Sie die Knowledgebase "{selectedKnowledgeBase.name}" wirklich löschen?
                    </Typography>
                </Box>
                <Box px={'20%'} width='100%' display="flex" alignItems="center" justifyContent="space-between">
                    <Button
                        size="small" variant="outlined" color="black"
                        onClick={() => handleDeleteKnowledgeBaseModalClose()}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        size="small" variant="outlined" color="error"
                        onClick={onDeleteButtonClick}
                    >
                        Löschen
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteKnowledgeBaseModal
