import React, {useContext, useState} from "react";
import {KnowledgeContext} from "../../context/knowledge.context";
import axiosInstance from "../../axiosInstance"
import KnowledgebaseForm from "./knowledgebase-form";
import {AuthContext} from "../../context/auth.context";

const CreateKnowledgebase = ({setSelectedItem, knowledgebaseToEdit}) => {

    const {addKnowledgeBase, updateKnowledgeBase} = useContext(KnowledgeContext)
    const {getEmail} = useContext(AuthContext)
    const [loading, setLoading] = useState(false)


    const SERVER_HOST = window.config.apiUrl;

    const handleCreateKnowledgeBase = (users, sources, title, description) => {
        setLoading(true)
        axiosInstance.request({
            url: `${SERVER_HOST}/topics`,
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                name: title,
                description: description,
            }
        }).then(res => {
            let new_topic_id = res.data.id;
            addKnowledgeBase(
                {
                    id: new_topic_id,
                    name: res.data.name,
                    description: res.data.description,
                    state: 'processing'
                }
            )
            sources.forEach(function(source, index) {
                if(source.type === 'url') {
                    axiosInstance.request({
                        url: `${SERVER_HOST}/topics/${res.data.id}/sources/website`,
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        data: {
                            url: source.name,
                            recursive: false,
                        }
                    }).then(res => {
                        console.log(res.data)
                    }).catch(err => {
                        console.log(err)
                    })
                } else if(source.type === 'pdf') {
                    const formData = new FormData();
                    formData.append('file', source.data);
                    axiosInstance.request({
                        url: `${SERVER_HOST}/topics/${new_topic_id}/sources/pdf?pdf_importer=fitz`,
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData
                    }).then(res => {
                        console.log(res.data);
                    }).catch(err => {
                        console.log(err)
                    })

                }
            })
            users.forEach(function(source, index) {

                if(source !== getEmail()) {
                    axiosInstance.request({
                        url:`${SERVER_HOST}/topics/${res.data.id}/users/${source}`,
                        method: 'post'
                    }).then(res => console.log(res.data)).catch(err => console.log(err))
                }
            })
            setLoading(false)
            setSelectedItem("Q&A")
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
        setLoading(false)
    }

    return(
        <KnowledgebaseForm
            loading={loading}
            knowledgebaseToEdit={knowledgebaseToEdit}
            setSelectedItem={setSelectedItem}
            onFinalClick={handleCreateKnowledgeBase}
        />
    )
}

export default CreateKnowledgebase
