import './styles/app.css';
import './styles/fonts.css'
import {Button, FormControl, TextField, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useContext, useState} from "react";
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material/styles";

import Logo from "./icons/saige_Logo_groß.png"

import CssBaseline from "@mui/material/CssBaseline";
import myThemeOptions from "./MyTheme";
import {AuthContext} from "./context/auth.context";
import axios from "axios";

const theme = createTheme(myThemeOptions)

const KEYCLOAK_URL = window.config.KEYCLOAK_URL;
const KEYCLOAK_REALM = window.config.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = window.config.KEYCLOAK_CLIENT_ID;

const Login = () => {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {setAuthentication, setToken} = useContext(AuthContext)
    const [showErrMsg, setShowErrMsg] = useState(false)
    const [labelColorUsername, setLabelColorUsername] = useState('grey');
    const [isFocusedUsername, setIsFocusedUsername] = useState(false);
    const [labelColorPassword, setLabelColorPassword] = useState('grey');
    const [isFocusedPassword, setIsFocusedPassword] = useState(false);

    const handleSubmit = async () => {
        let url = `${KEYCLOAK_URL}realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;
        axios.request({
            url: url,
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: new URLSearchParams({
                'grant_type': 'password',
                'client_id': KEYCLOAK_CLIENT_ID,
                'username': email,
                'password': password,
            }).toString()
        }).then(res => {
            setToken(res.data.access_token, res.data.refresh_token);
            setAuthentication(true);
            setShowErrMsg(false);
        }).catch(err => {
            console.log(err);
            setShowErrMsg(true);
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && (password !== '' && email !== '')) {
            handleSubmit()
        }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleFocusUsername = () => {
        setLabelColorUsername('primary.main');
        setIsFocusedUsername(true);
    };

    const handleBlurUsername = () => {
        setLabelColorUsername('grey');
        setIsFocusedUsername(false);
    };

    const handleFocusPassword = () => {
        setLabelColorPassword('primary.main');
        setIsFocusedPassword(true);
    };

    const handleBlurPassword = () => {
        setLabelColorPassword('grey');
        setIsFocusedPassword(false);
    };


    return(
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container maxWidth="xxl" variant='login' sx={{height: '100vh', flexDirection: 'column'}}>
                <Box sx={{pb: 5}} maxWidth={300}>
                    <img src={Logo} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Logo"/>
                    <Typography className="font" textAlign="center">We kick your corporate brain!</Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'white.main',
                        p: 4,
                        borderRadius: 5}}
                >
                    <FormControl>
                        <TextField
                            onKeyPress={handleKeyPress}
                            variant="outlined"
                            label="Email"
                            sx={{
                                minWidth: '300px',
                                pb: 3,
                                "& .MuiOutlinedInput-root:hover": {
                                    "& > fieldset": {
                                        borderColor: isFocusedUsername? "#E94E1B": "text.sixth"
                                    }
                                }}}
                            InputLabelProps={{
                                style: {
                                    color: isFocusedUsername ? '#E94E1B' : labelColorUsername,
                                },
                            }}
                            onFocus={handleFocusUsername}
                            onBlur={handleBlurUsername}
                            value={email}
                            onChange={handleEmailChange}
                        >
                        </TextField>
                        <TextField
                            onKeyPress={handleKeyPress}
                            label="Password"
                            type="password"
                            sx={{minWidth: '300px',  pb: 4, "& .MuiOutlinedInput-root:hover": {
                                    "& > fieldset": {
                                        borderColor: isFocusedPassword? "#E94E1B": "text.sixth"
                                    }
                                }}}
                            InputLabelProps={{
                                style: {
                                    color: isFocusedPassword ? '#E94E1B' : labelColorPassword,
                                },
                            }}
                            onFocus={handleFocusPassword}
                            onBlur={handleBlurPassword}
                            value={password}
                            onChange={handlePasswordChange}>
                        </TextField>
                        {showErrMsg && <Typography sx={{color: 'error.main', mb: 4}}>E-Mail und Passwort passen nicht zusammen!</Typography>}
                        <Button size="large" onClick={handleSubmit} sx={{background: 'linear-gradient( 45deg, #FFB400 0%, #E94E1B 100%)', color: 'white.main', fontWeight: 'bold', borderRadius: 1}}>Anmelden</Button>
                    </FormControl>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default Login;
