const myThemeOptions = {
    /* Custom variables */
    /**/headline: {
        fontColor: '#E94E1B',
        iconColor: '#E94E1B'
    },
    palette: {
        primary: {
            main: '#E94E1B',
            contrastText: '#fff',
        },
        secondary: {
            main: '#FFB400',
            contrastText: '#fff',
        },
        error: {
            main: '#FF0000',
            contrastText: '#fff'
        },
        green: {
            main: '#009933',
        },
        action: {
            main: '#000',
        },
        warning: {
            main: '#FFE816',
            contrastText: '#000'
        },
        success: {
            main: '#68D86B',
            contrastText: '#000'
        },
        black: {
            main: '#2B2B2B',
            contrastText: '#fff',
        },
        blue: {
            main: '#0061A7',
            contrastText: '#fff',
            stepperLine: '#3185E6'
        },
        white: {
            main: '#ffffff',
        },
        gray: {
            main: '#707070',
            divider: '#B7B7B7',
            uploadFieldBg: '#F5F5F5'
        },
        text: {
            primary: '#2B2B2B',
            secondary: '#505050',
            third: '#707070',
            fourth: '#909090',
            fifth: '#B0B0B0',
            sixth: '#D0D0D0',
            seventh: '#F0F0F0',
            eigth: '#FFFFFF',
            highlight: '#c06100',
        }
    },
    typography: {
        /* Standard  h1, h2, h3, h4, h5, h6, subtitle1, subtitle2, body1, body2, button, caption, overline */
        fontFamily: ['Varela, sans-serif'],
        appBarTitle: {
            color: '#000',
            fontWeight: 700,
            fontSize: '1.25rem',
            lineHeight: 1.5
        },
        appBarLogo: {
            color: '#E94E1B',
            fontWeight: 700,
            textDecoration: 'none',
            fontSize: '1.25rem'
        },
        appBarText: {
            color: '#000',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5
        },
        menu: {
            color: '#000',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: 3.0
        },
        modalDialog: {
            color: '#fff',
            fontWeight: 700,
            fontSize: '1.2rem'
        },
        body1: {
            color: '#000'
        },
        body3: {
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.3rem'
        },
        button: {
            fontSize: '1rem',
        },
        formLabels: {
            fontSize: '1rem'
        },
        formSubtiles: {
            fontSize: '1rem',
            fontWeight: 700,
        },
        logoSubtitle: {
            fontFamily: ['Comfortaa, sans-serif'],
        }
    },
    components: {
        MuiContainer: {
            variants: [
                {
                    props: {variant: 'login'},
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#EFF2F4',
                    }
                }
            ]
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        borderRadius: '4px',
                        borderColor: '#B7B7B7',
                    },

                },
            ]
        },
        MuiButton: {
            defaultProps: {
                disableRipple: false
            },
            variants: [
                {
                    props: {variant: 'bigfont'},
                    style: {
                        color: '#E94E1B',
                        fontWeight: 200,
                        textDecoration: 'none',
                        fontSize: '1.25rem',
                        textTransform: 'none'
                    },
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({
                    background: 'white',
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 10px 0px #E94E1B 16%',
                    color: 'black'
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.className === 'formTab' &&
                        !ownerState.selected && {
                            backgroundColor: '#A9A9A9',
                            color: '#fff',
                        }),
                    ...(ownerState.className === 'formTab' &&
                        ownerState.selected && {
                        backgroundColor: '#E94E1B',
                        color: '#fff',
                    }),
                }),
            },
        },
        // TODO: MuiBadge-anchorOriginBottomRight --> verschieben nach oben
        MuiBadge: {
            styleOverrides: {
                anchorOriginBottomRightRectangular: ({ownerState}) => ({
                    ...(ownerState.anchorOrigin.vertical === 'bottom' &&
                        ownerState.anchorOrigin.horizontal === 'right' && {
                            bottom: 0
                        }),
                }),
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'searchfield'},
                    style: {
                        backgroundColor: '#fff',
                        border: 'solid 2px #fff'
                    },
                },
                {
                    props: {variant: 'formparts'},
                    style: {
                        border: 'solid 1px #B7B7B7',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    },
                }
            ]
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: '#000',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    padding: '8px'
                },
                action: {
                    color: '#fff',
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    marginTop: '0px'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0px !important'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.disabled && {
                            color: '#A9A9A9'
                        }),
                    ...(!ownerState.disabled && {
                        color: '#2B2B2B'
                    })
                }),
                label: {
                    fontSize: '1rem'
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    paddingTop: '40px',
                    paddingBottom: '20px'
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root:  ({ownerState}) => ({
                    ...(ownerState.item &&
                        ownerState.className === "formGrids" && {
                            height: '56px',
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                    ...(ownerState.item &&
                        ownerState.className === "formGridsRGrp" && {
                            display: 'flex',
                            justifyContent: 'left',
                            justifyItems: 'center',
                            alignItems: 'center'
                        }),
                }),
            }
        }
    }
};

export default myThemeOptions;
