import React, {useState} from "react";
import '../styles/app.css';
import Box from "@mui/material/Box";
import SideMenu from "../components/side-menu";
import QA from "../components/q-and-a";
import CreateKnowledgebase from "../components/edit-knowledgebase-collection/create-knowledgebase";
import EditKnowledgebase from "../components/edit-knowledgebase-collection/edit-knowledgebase";

const MainPage = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState("Q&A")
    const [knowledgebaseToEdit, setKnowledgebaseToEdit] = useState({})


    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 60px)',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#EFF2F4',
            }}
        >
            <Box
                sx={{
                    width: '60px',
                    height: '100%',
                }}
            >
                <SideMenu
                    selectedItem={selectedMenuItem}
                    setSelectedItem={setSelectedMenuItem}
                />
            </Box>
            <Box
                sx={{
                    width: 'calc(100% - 60px)',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {selectedMenuItem === "Q&A" &&
                    <QA
                        setSelectedItem={setSelectedMenuItem}
                        setKnowledgebaseToEdit={setKnowledgebaseToEdit}
                    />
                }
                {selectedMenuItem === "Neu" &&
                    <CreateKnowledgebase
                        setSelectedItem={setSelectedMenuItem}
                        knowledgebaseToEdit={{}}
                    />
                }
                {selectedMenuItem === 'Edit' &&
                    <EditKnowledgebase
                        setSelectedItem={setSelectedMenuItem}
                        knowledgebaseToEdit={knowledgebaseToEdit}
                    />
                }
            </Box>
        </Box>

    );
}

export default MainPage;
