import Box from "@mui/material/Box";
import {
    Button,
    ButtonBase, Divider, Fade,
    InputAdornment,
    TextField, Tooltip, Typography
} from "@mui/material";
import React, {Fragment, useContext, useState} from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import {AuthContext} from "../../context/auth.context";
import {Search, Close} from "@mui/icons-material";
import DeleteKnowledgeBaseModal from "../modals/delete-knowledge-base-modal";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import {blue, red} from "@mui/material/colors";

const KnowledgeBases = (
    {
        knowledgeBases,
        selectedKnowledgeBase,
        handleKnowledgeBaseClick,
        setKnowledgebaseToEdit,
        setSelectedItem
    }
    ) => {

    const [searchFieldOpen, setSearchFieldOpen] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(true)
    const [hoverItem, setHoverItem] = useState()
    const [openDeleteKnowledgeBaseModal, setOpenDeleteKnowledgeBaseModal] = useState(false);
    const [knowledgeBaseToDelete, setKnowledgeBaseToDelete] = useState({})
    const {userRole} = useContext(AuthContext)
    const [searchTerm, setSearchTerm] = useState('');

    const handleDeleteKnowledgeBaseModalClose = () => setOpenDeleteKnowledgeBaseModal(false)

    const handleDeleteIconClick = (item, event) =>  {
        event.stopPropagation();
        setOpenDeleteKnowledgeBaseModal(true)
        setKnowledgeBaseToDelete(item)
    }

    const handleEditIconClick = (item, event) => {
        event.stopPropagation();
        setKnowledgebaseToEdit(item)
        setSelectedItem('Edit')

    }

    const handleSearchClose = () => {
        setSearchFieldOpen(false);
        setSearchTerm("");
    }

    return (
        <Box
            bgcolor='#FFF'
            sx={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                height: 'calc(100vh - 76px)',
                maxHeight: 'calc(100vh - 76px)',
                width: 350

            }}
        >
            <Box
                sx={{
                    pt: 1,
                    mb: 1,
                }}
            >
                {!searchFieldOpen?
                    <Box
                        sx={{
                            pl: 2,
                            pr:1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '90%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                            }}
                        >
                            <Button
                                onClick={() => setSelectedItem("Neu")}
                                variant='outlined'
                                startIcon={<AddIcon/>}
                                fullWidth
                                style={{
                                    fontSize: 14,
                                    height: 40
                                }}
                            >
                                Neue Knowledgebase
                            </Button>
                        </Box>
                        <IconButton
                            onClick={() => setSearchFieldOpen(true)}
                        >
                            <Search
                                color={'primary'}
                                fontSize='large'
                            />
                        </IconButton>
                    </Box>
                    :
                    <Box
                        sx={{
                            pl: 2,
                            pr: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '80%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                            }}
                        >
                            <TextField
                                placeholder={'Suche...'}
                                id="input-with-icon-textfield"
                                InputProps={{
                                    style: {
                                        background: 'transparent',
                                        height: 40
                                    },
                                }}
                                variant="outlined"
                                onChange={event => {
                                    setSearchTerm(event.target.value)
                                }}
                            />
                        </Box>
                        <IconButton
                            onClick={handleSearchClose}
                        >
                            <Close
                                color='action'
                                fontSize='large'
                            />
                        </IconButton>
                    </Box>

                }
            </Box>
            <Box sx={{
                    flex: 1,
                    maxHeight: 'calc(100% - 67px)',
                    overflowY: 'auto',
                    scrollbarWidth: 'none', // Entfernt die Scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Entfernt die Scrollbar in Webkit-Browsern (Chrome, Safari, Edge)
                    },
                }}
            >
                {/* List of topics */}
                {knowledgeBases.filter((val) => {
                    if (searchTerm === "") {
                        return val
                    } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase()) || val.description.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val
                    } else {
                        return null
                    }
                }).map((item ,index) => (
                    <Box
                        key={index}
                    >
                        <ButtonBase
                            sx={{
                                width: '100%',
                                background: item.name === selectedKnowledgeBase.name? '#EDEDED': 'none',
                            }}
                            onClick={() =>handleKnowledgeBaseClick(item)}
                        >
                            <Box
                                sx={{
                                    width:'100%',
                                    minHeight: 90,
                                    maxHeight: 180,
                                    height: item.name === selectedKnowledgeBase.name? 'auto': 90,
                                    //borderBottom:'solid 1px #D0D0D0',
                                    borderRight: item.name === selectedKnowledgeBase.name? '2px solid #E94E1B': 'none',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    py:1,
                                    pl:2,
                                    pr:2,
                                }}
                                onMouseEnter={() => {
                                    setMouseEnter(true)
                                    setHoverItem(index)
                                }}
                                onMouseLeave={() => setMouseEnter(false)}
                            >
                                <Box
                                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                                >
                                    <Typography
                                        fontWeight={'bold'}
                                        fontSize={18}
                                        overflow={'hidden'}
                                        whiteSpace= 'nowrap'
                                        textOverflow= 'ellipsis'
                                        color={item.state === 'ready' ? 'text.primary' : 'text.fifth'}
                                    >
                                        {item.name}
                                    </Typography>
                                    {mouseEnter && hoverItem === index && (userRole.includes("knowledge-edit") && userRole.includes("knowledge-delete")) &&

                                        <Box
                                            sx={{display: 'flex', flexDirection: 'row', ml: 3}}
                                        >
                                            {userRole.includes("knowledge-edit") &&
                                                <ModeOutlinedIcon
                                                    onClick={
                                                        (event) =>{
                                                            handleEditIconClick(item, event)
                                                        }
                                                    }
                                                    size="small"
                                                    sx={{
                                                        "&:hover": {
                                                            background: "none",
                                                            color: 'primary.main',
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                />
                                            }
                                            {userRole.includes("knowledge-delete") &&
                                                <DeleteOutlineIcon
                                                    onClick={(event) => handleDeleteIconClick(item, event)}
                                                    size="small"
                                                    sx={{
                                                        ml: 0,
                                                        "&:hover": {
                                                            background: "none",
                                                            color: 'primary.main',
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                />
                                            }
                                        </Box>
                                    }
                                </Box>
                                <Typography
                                    fontSize={13}
                                    color={item.name === selectedKnowledgeBase.name? 'text.primary' : 'text.third'}
                                    mt={0.5}
                                    textAlign='start'
                                    style={ item.name === selectedKnowledgeBase.name?
                                        {
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 5,
                                            textOverflow: 'ellipsis',
                                        }:
                                        {
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 1,
                                            textOverflow: 'ellipsis',
                                        }
                                    }
                                >
                                    {item.description}
                                </Typography>
                            </Box>
                        </ButtonBase>
                        <Divider
                            variant='middle'
                            sx={{
                                backgroundColor: 'rgba(226, 86, 38, 0.3)',
                            }}
                        />
                    </Box>

                ))}
            </Box>
            <DeleteKnowledgeBaseModal
                openDeleteKnowledgeBaseModal={openDeleteKnowledgeBaseModal}
                handleDeleteKnowledgeBaseModalClose={handleDeleteKnowledgeBaseModalClose}
                selectedKnowledgeBase={knowledgeBaseToDelete}
            />
        </Box>
    )
}

export default KnowledgeBases;
