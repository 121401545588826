import KnowledgeBases from "./qa/knowledgeBases";
import QuestionTextField from "./qa/question-text-field";
import AnswerSection from "./qa/answer-section";
import SourceList from "./qa/source-list";
import knowledgeBases from "./qa/knowledgeBases";
import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useRef, useState} from "react";
import {KnowledgeContext} from "../context/knowledge.context";
import axiosInstance from "../axiosInstance";

const QA = ({setSelectedItem, setKnowledgebaseToEdit}) => {

    const SERVER_HOST = window.config.apiUrl;

    const [questionAsked, setQuestionAsked] = useState(false)
    const {knowledgeBase} = useContext(KnowledgeContext);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState({})
    const [selectedSource, setSelectedSource] = useState({})
    const [loading, setLoading] = useState(false)
    const chatBoxRef = useRef(null);
    const [question, setQuestion] = useState("");
    const [lastQuestion, setLastQuestion] = useState("");
    const [messages, setMessages] = useState([])
    const [uuid, setUuid] = useState("");
    const [state, setState] = useState("");
    const [paragraphs, setParagraphs] = useState([]);
    const [openSourceModal, setOpenSourceModal] = useState(false);

    const handleSourceModalClose = () => setOpenSourceModal(false);

    function handleQuestionChange(e) {
        setQuestion(e.target.value)
    }

    useEffect(() => {
        if (chatBoxRef.current !== null) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleKnowledgeBaseClick = (clickedKnowledgeBase) => {
        if (clickedKnowledgeBase.id === selectedKnowledgeBase.id) {
            setSelectedKnowledgeBase({})
            setMessages([])
            setLastQuestion("")
            setParagraphs([])
        } else {
            setQuestionAsked(false)
            setSelectedKnowledgeBase(clickedKnowledgeBase)
            setUuid(clickedKnowledgeBase.id)
            setState(clickedKnowledgeBase.state)
            setMessages([])
            setLastQuestion("")
            setParagraphs([])
        }

    }

    const handleSourceClick = (clickedSource) => {
        setSelectedSource(clickedSource)
        setOpenSourceModal(true)
    }

    async function handleSendMessage(event) {
        if (event.type === 'click' || event.key === "Enter") {

            setMessages([])
            setParagraphs([])
            //setMessages((prevMessages) => [...prevMessages, {type: 'question', text: question}]);

            setLoading(true)

            axiosInstance.request({
                url: `${SERVER_HOST}/generation/answer?question=${question}&topic_id=${uuid}`,
                method: 'get'
            }).then(
                res => {
                    setParagraphs(res.data.paragraphs)
                    setMessages((prevMessages) => [...prevMessages, {type: 'answer', text: res.data.answer}]);
                    setLoading(false)
                    setQuestionAsked(true)
                }
            ).catch(
                _ => {
                    setMessages(prevMessages => [...prevMessages, {
                        type: 'error',
                        text: "Es ist ein Fehler beim Laden der Antwort aufgetreten. Bitte versuch es später noch einmal."
                    }]);
                    setLoading(false)
                    setQuestionAsked(true)
                }
            )

            setLastQuestion(question);
            setQuestion("")
        }

    }

    return (
        <Box sx={{px: 2, pt: 2, flex: 1, display: 'flex', justifyContent: 'start'}}>
            <Box>
                <KnowledgeBases
                    setSelectedItem={setSelectedItem}
                    setKnowledgebaseToEdit={setKnowledgebaseToEdit}
                    selectedKnowledgeBase={selectedKnowledgeBase}
                    knowledgeBases={knowledgeBase}
                    handleKnowledgeBaseClick={handleKnowledgeBaseClick}
                />
            </Box>
            <Box
                sx={{
                    flex: 1,
                }}
            >
                {!questionAsked ?
                    <QuestionTextField
                        lastQuestion={lastQuestion}
                        question={question}
                        handleQuestionChange={handleQuestionChange}
                        handleSendMessage={handleSendMessage}
                        loading={loading}
                        withSource={false}
                        state={state}
                    /> :
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',

                        }}
                    >
                        <Box
                            sx={{
                                width: '80%'
                            }}

                        >
                            <QuestionTextField
                                lastQuestion={lastQuestion}
                                question={question}
                                handleQuestionChange={handleQuestionChange}
                                handleSendMessage={handleSendMessage}
                                loading={loading}
                                withSource={true}
                                state={state}
                            />
                            <AnswerSection
                                messages={messages}
                                question={question}
                                handleQuestionChange={handleQuestionChange}
                                handleSendMessage={handleSendMessage}
                                lastQuestion={lastQuestion}
                                loading={loading}
                                chatBoxRef={chatBoxRef}/>
                        </Box>
                        <Box>
                            <SourceList
                                paragraphs={paragraphs}
                                handleSourceClick={handleSourceClick}
                                knowledgeBase={knowledgeBases}
                            />
                        </Box>
                    </Box>

                }

            </Box>
            {/*Modal displaying source details and providing the button to visit the source*/}
            <Modal
                open={openSourceModal}
                onClose={handleSourceModalClose}
            >
                <Box sx={{
                    borderRadius: 5,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {selectedSource.sourceType === 'url' && <Button endIcon={<OpenInNewIcon/>}
                            onClick={() => window.open(`${selectedSource.name}`, '_blank')}>
                        Quelle
                    </Button>}
                    <Typography id="modal-modal-description" sx={{mt: 2}} textAlign="justify">
                        {selectedSource.content}
                    </Typography>
                </Box>
            </Modal>
        </Box>
    )
}


export default QA;


{/*

<Grid container spacing={0}>
                    <Grid key={1} item  xs={3} md={3} lg={2.5}>
                        <KnowledgeBases
                            setSelectedItem={setSelectedItem}
                            setKnowledgebaseToEdit={setKnowledgebaseToEdit}
                            selectedKnowledgeBase={selectedKnowledgeBase}
                            knowledgeBases={knowledgeBase}
                            handleKnowledgeBaseClick={handleKnowledgeBaseClick}
                        />
                    </Grid>
                    <Grid key={2} item xs={9} md={9} lg={9.5}>
                        <Grid item xs={12}>
                            <QuestionTextField
                                lastQuestion={lastQuestion}
                                question={question}
                                handleQuestionChange={handleQuestionChange}
                                handleSendMessage={handleSendMessage}
                                loading={loading}
                            />
                        </Grid>
                        <Grid item container>
                            <Grid item xs={7} md={7} lg={9}>
                                <AnswerSection
                                    messages={messages}
                                    question={question}
                                    handleQuestionChange={handleQuestionChange}
                                    handleSendMessage={handleSendMessage}
                                    lastQuestion={lastQuestion}
                                    loading={loading}
                                    chatBoxRef={chatBoxRef}/>
                            </Grid>
                            <Grid item xs={5} md={5} lg={3}>
                                {paragraphs.length !== 0 &&
                                    <SourceList
                                        paragraphs={paragraphs}
                                        handleSourceClick={handleSourceClick}
                                        knowledgeBase={knowledgeBases}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

*/
}
