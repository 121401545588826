import {createContext, useEffect, useState} from 'react'
import axiosInstance from '../axiosInstance';

const SERVER_HOST = window.config.apiUrl;


export const KnowledgeContext = createContext({
    knowledgeBase: [],
    setKnowledgeBase: () => null,
    deleteKnowledgeBase: () => null,
    addKnowledgeBase: () => null,
    updateKnowledgeBase: () => null,
});

export const KnowledgeProvider = ({children}) => {
    const [knowledgeBase, setKnowledgeBase] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            const response = await axiosInstance.get(
                    `${SERVER_HOST}/topics`
                );
            if(response.status === 200) {
                console.log(response.data)
                let topics = response.data

                topics.sort((a,b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                })
                setKnowledgeBase(topics)
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    function setKnowledgeBasesAfterDelete(deletedKnowledgeBase) {

        const updatedData = knowledgeBase.filter((obj) => obj.name !== deletedKnowledgeBase.name);
        setKnowledgeBase(updatedData);
    }

    function setKnowledgeBaseAfterAdd(newKnowledgeBase) {

        setKnowledgeBase([...knowledgeBase, newKnowledgeBase]);
    }

    function setKnowledgeBaseAfterUpdate(updatedKnowledgeBase) {

        setKnowledgeBase(knowledgeBase.map(obj => {
            if(obj.id === updatedKnowledgeBase.id) {
                return {
                    ...obj,
                    ['name']: updatedKnowledgeBase.name,
                    ['description']: updatedKnowledgeBase.description,
                    ['state']: updatedKnowledgeBase.state,
                };
            }
            return obj
        }))
    }

    const value = {
        knowledgeBase,
        setKnowledgeBase,
        deleteKnowledgeBase: setKnowledgeBasesAfterDelete,
        addKnowledgeBase: setKnowledgeBaseAfterAdd,
        updateKnowledgeBase: setKnowledgeBaseAfterUpdate,
    };




    return <KnowledgeContext.Provider value={value}>{children}</KnowledgeContext.Provider>
}
