import * as React from 'react';
import {Route, Routes} from "react-router";
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ResponsiveAppBar from './components/responsive-app-bar';
import MainPage from "./pages/MainPage";
import myThemeOptions from "./MyTheme";
import Box from "@mui/material/Box";
import './fonts/Gibson-Regular.ttf'
import {useContext, useEffect} from "react";
import axios from "axios";
import {AuthContext} from "./context/auth.context";

const theme = createTheme(myThemeOptions);

const KEYCLOAK_URL = window.config.KEYCLOAK_URL;
const KEYCLOAK_REALM = window.config.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = window.config.KEYCLOAK_CLIENT_ID;

const App = () => {
    const {refreshToken, setToken} = useContext(AuthContext);

    useEffect(() => {
        const tokenRefresh = () => {
            let url = `${KEYCLOAK_URL}realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`;
            axios.request({
                url: url,
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': refreshToken,
                    'client_id': KEYCLOAK_CLIENT_ID,
                })
            }).then(res => {
                setToken(res.data.access_token, res.data.refresh_token);
            }).catch(err => {
                console.log(err);
            })
        }

        window.addEventListener('keydown', tokenRefresh);
        window.addEventListener('click', tokenRefresh);

        return () => {
            window.removeEventListener('click', tokenRefresh);
            window.removeEventListener('keydown', tokenRefresh);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box
                sx={{
                    width: '100wh',
                    height: '100vh',
                    maxHeight: '100vh',
                }}
            >
                <ResponsiveAppBar/>
                <Routes>
                    <Route path="/" element={<MainPage/>}></Route>
                    {/*<Route path="/my-content/:contentFilter" element={<MyContentPage/>}></Route>*/}
                </Routes>
            </Box>
        </ThemeProvider>
    );
}

export default App;
