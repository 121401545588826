import Box from "@mui/material/Box";
import {CircularProgress, TextField} from "@mui/material";
import React from "react";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import IconButton from "@mui/material/IconButton";


const QuestionTextField = ({lastQuestion, question, handleQuestionChange, handleSendMessage, loading, withSource, state}) => {

    function getPlaceholder() {
        let placeholder = 'Stelle eine Frage';

        if (lastQuestion) {
            placeholder = lastQuestion;
        } else if ( state === 'processing' ) {
            placeholder = 'Ich bin am Lernen';
        } else if ( state === 'created' ) {
            placeholder = 'Bisher besitze ich noch kein Wissen';
        }

        return placeholder;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 5, px: 15}}>
            <Box width='100%' sx={{ justifyContent: 'start', display: 'flex', alignItems: 'center', pt: 1}}>
                <Box
                    sx={{
                        flex: 1,
                        mr: 3,
                        display: 'flex',
                        justifyContent: 'start'
                    }}
                >
                    <TextField
                        variant="standard"
                        placeholder={ getPlaceholder() }
                        size="medium"
                        fullWidth
                        value={question}
                        onChange={handleQuestionChange}
                        onKeyDown={handleSendMessage}
                        disabled={state !== 'ready'}
                    />
                </Box>
                <IconButton
                    onClick={handleSendMessage}
                    size='small'
                    sx={{
                        borderRadius: 1,
                        backgroundColor: 'primary.main'
                    }}
                    disabled={ state !== 'ready' }
                >
                    <SendOutlinedIcon
                        color={ state === 'ready' ? 'white' : 'primary.main' }
                    />
                </IconButton>
            </Box>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                {loading &&
                    <CircularProgress/>}
            </Box>
        </Box>
    )
}

export default QuestionTextField;
