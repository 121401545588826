import React, {useContext, useState} from "react";
import KnowledgebaseForm from "./knowledgebase-form";
import axiosInstance from "../../axiosInstance";
import {KnowledgeContext} from "../../context/knowledge.context";

const EditKnowledgebase = ({knowledgebaseToEdit, setSelectedItem}) => {

    const SERVER_HOST = window.config.apiUrl;
    const [loading, setLoading] = useState(false);

    const {updateKnowledgeBase} = useContext(KnowledgeContext);

    const handleEditKnowledgebaseClick = (newUsers, deletedUsers, deletedSources, newSources, title, description) => {

        setLoading(true)

        if(title !== knowledgebaseToEdit.name || description !== knowledgebaseToEdit.description) {
            axiosInstance.request({
                url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}`,
                method: 'put',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    name: title,
                    description: description,
                }
            }).then(res => {
                updateKnowledgeBase({id: knowledgebaseToEdit.id, name: title, description: description,
                    state: knowledgebaseToEdit.state})
                newSources.forEach(function(source, index) {
                    if(source.type === 'url') {
                        axiosInstance.request({
                            url: `${SERVER_HOST}/topics/${res.data.id}/sources/website`,
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            data: {
                                url: source.name,
                                recursive: false,
                            }
                        }).then(res => {
                            if (index === 0) {
                                updateKnowledgeBase({id: knowledgebaseToEdit.id, name: knowledgebaseToEdit.name,
                                    description: knowledgebaseToEdit.description, state: 'processing'})
                            }
                            console.log(res.data)
                        }).catch(err => {
                            console.log(err)
                        })
                    } else if(source.type === 'pdf') {
                        const formData = new FormData();
                        formData.append('file', source.data);
                        axiosInstance.request({
                            url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/sources/pdf?pdf_importer=fitz`,
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'multipart/form-data'
                            },
                            data: formData
                        }).then(res => {
                            if (index === 0) {
                                updateKnowledgeBase({id: knowledgebaseToEdit.id, name: knowledgebaseToEdit.name,
                                    description: knowledgebaseToEdit.description, state: 'processing'})
                            }
                            console.log(res.data)
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                })
                deletedSources.forEach(function(source, index) {
                    axiosInstance.request({
                        url: `${SERVER_HOST}/topics/${res.data.id}/sources/${source.id}`,
                        method: 'delete',
                        headers: {
                            'Accept': 'application/json',
                        },
                    }).then(res => {
                        console.log(res.data)
                    }).catch(err => {
                        console.log(err)
                    })
                })
                setSelectedItem("Q&A")
                newUsers.forEach(function(source, index) {
                    axiosInstance.request({
                        url:`${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/users/${source}`,
                        method: 'post'
                    }).then(res => console.log(res.data)).catch(err => console.log(err))
                })
                deletedUsers.forEach(function(source, index) {
                    axiosInstance.request({
                        url:`${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/users/${source}`,
                        method: 'delete'
                    }).then(res => console.log(res.data)).catch(err => console.log(err))
                })
                setLoading(false)
                setSelectedItem("Q&A")
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
        } else {
            newSources.forEach(function(source, index) {
                if(source.type === 'url') {
                    axiosInstance.request({
                        url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/sources/website`,
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        data: {
                            url: source.name,
                            recursive: false,
                        }
                    }).then(res => {
                        if (index === 0) {
                            updateKnowledgeBase({id: knowledgebaseToEdit.id, name: knowledgebaseToEdit.name,
                                description: knowledgebaseToEdit.description, state: 'processing'})
                        }
                        console.log(res.data)
                    }).catch(err => {
                        console.log(err)
                    })
                } else if(source.type === 'pdf') {
                    const formData = new FormData();
                    formData.append('file', source.data);
                    axiosInstance.request({
                        url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/sources/pdf?pdf_importer=fitz`,
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        data: formData
                    }).then(res => {
                        if (index === 0) {
                            updateKnowledgeBase({id: knowledgebaseToEdit.id, name: knowledgebaseToEdit.name,
                                description: knowledgebaseToEdit.description, state: 'processing'})
                        }
                        console.log(res.data)
                    }).catch(err => {
                        console.log(err)
                    })
                }
            })
            deletedSources.forEach(function(source, index) {
                axiosInstance.request({
                    url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/sources/${source.id}`,
                    method: 'delete',
                    headers: {
                        'Accept': 'application/json',
                    },
                }).then(res => {
                    console.log(res.data)
                }).catch(err => {
                    console.log(err)
                })
            })
            newUsers.forEach(function(source, index) {
                axiosInstance.request({
                    url:`${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/users/${source}`,
                    method: 'post'
                }).then(res => console.log(res.data)).catch(err => console.log(err))
            })
            deletedUsers.forEach(function(source, index) {
                axiosInstance.request({
                    url:`${SERVER_HOST}/topics/${knowledgebaseToEdit.id}/users/${source}`,
                    method: 'delete'
                }).then(res => console.log(res.data)).catch(err => console.log(err))
            })
            setLoading(false)
            setSelectedItem("Q&A")
        }
    }


    return(
        <KnowledgebaseForm
            loading={loading}
            knowledgebaseToEdit={knowledgebaseToEdit}
            setSelectedItem={setSelectedItem}
            onFinalClick={handleEditKnowledgebaseClick}
        />
    )
}

export default EditKnowledgebase;
