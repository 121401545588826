import Box from "@mui/material/Box";
import {ButtonBase, Typography} from "@mui/material";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const SideMenu = ({selectedItem, setSelectedItem}) => {

    const handleItemClick = (item) => {
        setSelectedItem(item)
    }

    return(
        <Box sx={{display:'flex', flexDirection:'column', alignItems: 'center', bgcolor: '#FFF', py: 3, height: 'calc(100vh - 60px)'}}>
            <ButtonBase onClick={() => handleItemClick('Q&A')}>
                <Box mb={4} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                    <SchoolOutlinedIcon fontSize='large' sx={{m:0}} color={selectedItem === "Q&A" || selectedItem === "Edit" || selectedItem === "Neu"? "primary": '#000'}/>
                    <Typography fontSize={11} color={selectedItem === "Q&A" || selectedItem === "Edit" || selectedItem === "Neu" ? "primary": '#000'}>Q&A</Typography>
                </Box>
            </ButtonBase>
            {/*<ButtonBase onClick={() => handleItemClick('Einstellungen')}>*/}
            {/*    <Box mb={4} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}>*/}
            {/*        <SettingsOutlinedIcon fontSize='large' sx={{m:0}} color={selectedItem === "Einstellungen"? "primary": '#000'}/>*/}
            {/*        <Typography fontSize={11} color={selectedItem === "Einstellungen"? "primary": '#000'}>Settings</Typography>*/}
            {/*    </Box>*/}
            {/*</ButtonBase>*/}
        </Box>
    )
}

export default  SideMenu;
