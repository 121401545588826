import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"
import React from "react";

const AnswerSection = ({messages, chatBoxRef}) => {
    return(
        <Box
            sx={{
                px: 15,
                maxHeight: '80vh',
                display: 'flex',
                flexDirection: 'column-reverse',
            }}
        >
            <Box
                ref={chatBoxRef}
                sx={{
                    overflowY: 'auto',
                }}>
                {messages.map((message, index) => (
                    <Box key={index}>
                        <Typography
                            color='primary'
                            fontWeight='bold'
                            sx={{
                                mb:1
                            }}
                        >
                            SAIGE
                        </Typography>
                        <Typography
                            textAlign="justify"
                            fontWeight={message.type === 'question'? 'bold': 'normal'}
                            color={message.type === 'error'? 'red': 'black.main'}
                            style={{whiteSpace: 'pre-line'}}
                        >
                            {message.text}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default AnswerSection;