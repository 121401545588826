import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Autocomplete, LinearProgress, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {KnowledgeContext} from "../../context/knowledge.context";
import LinkIcon from "@mui/icons-material/Link";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import axiosInstance from "../../axiosInstance";
import {AuthContext} from "../../context/auth.context";
import {LoadingButton} from "@mui/lab";

const KnowledgebaseForm = ({loading, knowledgebaseToEdit, setSelectedItem, onFinalClick}) => {

    const SERVER_HOST = window.config.apiUrl;

    const maxHeight = '60%'

    const fileInputRef = useRef(null);
    const [organization, setOrganization] = useState([])
    const [newSources, setNewSources] = useState([])
    const [deletedSources, setDeletedSources] = useState([])
    const [sources, setSources] = useState([])
    const [recursiveChecked, setRecursiveChecked] = useState(false)
    const [url, setUrl] = useState("")
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState(false)
    const [titleErrorMessage, setTitleErrorMessage] = useState("")
    const [description, setDescription] = useState('')
    const [users, setUsers] = useState([])
    const [newUsers, setNewUsers] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [urlSources, setUrlSources] = useState([]);
    const [pdfSources, setPDFSources] = useState([]);

    const {knowledgeBase} = useContext(KnowledgeContext)
    const {getEmail} = useContext(AuthContext)


    function findUniqueElement(newValue, reason) {

        if (reason === 'selectOption') {

            const element = newValue.filter(element => !users.includes(element))

            //Aus dem Lösch-Array entfernen, falls der User aus Versehen vorher entfernt wurde
            setDeletedUsers(prevArray => {
                const indexToRemove = prevArray.findIndex(obj => obj === element[0]);

                if (indexToRemove !== -1) {
                    // Das Objekt wurde im Array gefunden, entferne es
                    const newArray = [...prevArray];
                    newArray.splice(indexToRemove, 1);
                    return newArray;
                }

                // Das Array bleibt unverändert
                return prevArray;
            });
            setNewUsers(prevArray => [...prevArray, element[0]])
            setUsers(newValue)

        } else {

            const element = users.filter(element => !newValue.includes(element))

            if (element[0] !== getEmail()) {
                //Aus dem New-Array entfernen, falls der User aus Versehen vorher hingefügt wurde
                setNewUsers(prevArray => {
                    const indexToRemove = prevArray.findIndex(obj => obj === element[0]);

                    if (indexToRemove !== -1) {
                        // Das Objekt wurde im Array gefunden, entferne es
                        const newArray = [...prevArray];
                        newArray.splice(indexToRemove, 1);
                        return newArray;
                    }

                    // Das Array bleibt unverändert
                    return prevArray;
                })
                setDeletedUsers(prevArray => [...prevArray, element[0]])
                setUsers(newValue)
            }


        }
    }


    const handleChangeInUserBox = (event, newValue, reason) => {
        findUniqueElement(newValue, reason)

    };


    function onChangeUrl(value) {
        setUrl(value.target.value)
    }

    function onTitelChange(value) {
        setTitle(value.target.value)
    }

    function onDescriptionChange(value) {
        setDescription(value.target.value)
    }

    const handleRecursiveClick = () => {
        setRecursiveChecked(!recursiveChecked)
    }

    function handleFileUpload(event) {
        const file = event.target.files[0];
        const newSource = {type: "pdf", name: event.target.files[0].name, status: 0, data: file};
        setSources([...sources, newSource]);

        setDeletedSources(prevArray => {
            const indexToRemove = prevArray.findIndex(obj => obj.name === newSource.name);

            if (indexToRemove !== -1) {
                // Das Objekt wurde im Array gefunden, entferne es
                const newArray = [...prevArray];
                newArray.splice(indexToRemove, 1);
                return newArray;
            } else {
                setNewSources(prevArray => [...prevArray, newSource])
            }

            // Das Array bleibt unverändert
            return prevArray;
        })
    }

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (Object.keys(knowledgebaseToEdit).length !== 0) {
                setDeletedSources(prevArray => {
                    const indexToRemove = prevArray.findIndex(obj => obj.name === newSource.name);

                    if (indexToRemove !== -1) {
                        // Das Objekt wurde im Array gefunden, entferne es
                        const newArray = [...prevArray];
                        newArray.splice(indexToRemove, 1);
                        return newArray;
                    } else {
                        setNewSources([...newSources, {type: "url", name: url, status: 0, recursive: recursiveChecked}])
                    }

                    // Das Array bleibt unverändert
                    return prevArray;
                })
            }
            const newSource = {type: "url", name: url, status: 0, recursive: recursiveChecked};
            setSources([...sources, newSource]);


            setUrl("")
        }
    };

    const handleSourceDelete = (source) => {

        console.log(source)

        if (Object.keys(knowledgebaseToEdit).length !== 0) {
            setNewSources(prevArray => {
                const indexToRemove = prevArray.findIndex(obj => obj === source);

                if (indexToRemove !== -1) {
                    // Das Objekt wurde im Array gefunden, entferne es

                    const newArray = [...prevArray];
                    newArray.splice(indexToRemove, 1);
                    return newArray;
                } else {
                    setDeletedSources([...deletedSources, {id: source.id, name: source.name}])
                }
                // Das Array bleibt unverändert
                return prevArray;
            })


        }


        const updatedSources = sources.filter(item => item.name !== source.name)
        if (updatedSources.length === 0) {
            setSources([])
        } else {
            setSources(updatedSources)
        }
    }

    // if edit-mode - extract the given information from topic and prefill the form
    useEffect(() => {
        if (Object.keys(knowledgebaseToEdit).length === 0) {
            setUsers([getEmail()])
        } else {
            setTitle(knowledgebaseToEdit.name);
            setDescription(knowledgebaseToEdit.description);
            axiosInstance.request({
                url: `${SERVER_HOST}/topics/${knowledgebaseToEdit.id}`,
                method: 'get'
            }).then(res => {
                console.log(res.data)
                setSources(res.data.sources)
                setUsers(res.data.users.map(obj => obj.user_id))
            }).catch(err => console.log(err))
        }

    }, []);

    // update the pdf und url list of source gets added or deleted
    useEffect(() => {
        setUrlSources(sources.filter(item => item.type === 'url'));
        setPDFSources(sources.filter(item => item.type === 'pdf'));
    }, [sources]);


    useEffect(() => {

        axiosInstance.request({
            url: `${SERVER_HOST}/users`,
            method: 'get'
        }).then(
            res => {
                setOrganization(res.data.map(obj => obj.email))
            }
        ).catch(
            _ => {
                console.log("Fehler")
            }
        )

    }, []);

    const onEditAddClick = () => {
        if (!knowledgeBase.some((obj) => obj.name === title)) {
            if (title !== '') {
                onFinalClick(sources, title, description)
            } else {
                setTitleError(true);
                setTitleErrorMessage("Bitte tragen Sie einen Titel ein");
            }
        } else {
            setTitleError(true);
            setTitleErrorMessage("Titel bereits vergeben");
        }
    }

    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                px: 10,
                py: 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '90%',
                    pr: 10,
                }}
            >
                <Box
                    sx={{
                        width: '50%',
                        pr: 10,
                    }}
                >
                    <Typography
                        fontWeight='bold'
                        color='primary'
                        mb={3}
                    >
                        Name
                    </Typography>
                    <TextField
                        variant='outlined'
                        placeholder="Name der Knowledgebase..."
                        fullWidth
                        sx={{
                            mb: 5,
                            border: 'none',
                            "& fieldset":
                                {border: 'none'},
                        }}
                        InputProps={{
                            style: {
                                border: '1px solid #E94E1B'
                            },
                        }}
                        value={title}
                        onChange={onTitelChange}
                    />
                    <Typography
                        fontWeight='bold'
                        color='primary'
                        mb={3}
                    >
                        Beschreibung
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        placeholder="Beschreibung der Knowledgebase..."
                        sx={{
                            mb: 5,
                            border: 'none',
                            "& fieldset":
                                {border: 'none'},
                        }}
                        InputProps={{
                            style: {
                                border: '1px solid #E94E1B'
                            },
                        }}
                        value={description}
                        onChange={onDescriptionChange}
                    />
                    <Typography
                        fontWeight='bold'
                        color='primary'
                        mb={3}
                    >
                        Mitglieder
                    </Typography>
                    <Autocomplete
                        multiple
                        value={users}
                        onChange={handleChangeInUserBox}
                        options={organization}
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Hinzufügen"/>
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        width: '50%',
                        pl: 10,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        fontWeight='bold'
                        color='primary'
                        mb={3}
                    >
                        Quellen
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                                height: '100%',
                                pr: 5
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    mb: 3,
                                }}
                            >
                                <LinkIcon
                                    color='primary'
                                />
                                <Typography
                                    color='primary'
                                    fontWeight='bold'
                                    pl={2}
                                >
                                    Links
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant='outlined'
                                sx={{
                                    mb: 3,
                                    borderRadius: 1,
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    "& fieldset":
                                        {border: 'none'},
                                }}
                                InputProps={{
                                    style: {
                                        background: 'transparent',
                                        fontSize: 14,
                                        height: 40,
                                        border: '1px solid #E94E1B'
                                    },
                                }}
                                placeholder={"URL hinzufügen"}
                                value={url}
                                onChange={onChangeUrl}
                                onKeyDown={handleKeyDown}
                                size={'small'}
                            />
                            <Box
                                sx={{
                                    width: '100%',
                                    maxHeight: maxHeight,
                                    overflowY: 'auto',
                                }}
                            >
                                {urlSources.map((source, index) => (
                                    <Fragment>
                                        <Box key={index} display="flex" alignItems="center"
                                             justifyContent="space-between"
                                             height="42px" borderRadius={2} pl={2} pr={1} mb={0} bgcolor={"white.main"}
                                             width="100%">
                                            <Tooltip title={source.name}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between"
                                                     style={{
                                                         textOverflow: 'ellipsis',
                                                         whiteSpace: 'nowrap',
                                                         overflow: 'hidden'
                                                     }}>
                                                    <Typography fontSize={14}
                                                                color={source.state === 'ready' ? 'text.primary' : 'text.fifth'}>
                                                        {source.name}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title="Quelle löschen">
                                                <IconButton onClick={() => handleSourceDelete(source)} size="small">
                                                    <DeleteForeverOutlinedIcon color={'primary'}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box mb={2} mt={-1}>
                                            {source.state === 'processing' &&
                                                <LinearProgress style={{width: '100%'}}/>
                                            }
                                        </Box>
                                    </Fragment>
                                ))}
                                {urlSources.length === 0 &&
                                    <Typography textAlign='center'>Noch keine Url hinzugefügt</Typography>
                                }
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                height: '100%',
                                pl: 5
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    mb: 3,
                                }}
                            >
                                <DescriptionOutlinedIcon
                                    color='primary'
                                />
                                <Typography
                                    color='primary'
                                    fontWeight='bold'
                                    pl={2}
                                >
                                    PDFs
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    mb: 3,
                                }}
                            >
                                <Box
                                    onClick={handleUploadClick}
                                    sx={{
                                        width: '100%',
                                        height: 40,
                                        border: '1px solid #E94E1B',
                                        borderRadius: 2,
                                        borderSpacing: 15,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                        mb: 3,
                                    }}
                                >
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{display: 'none'}}
                                        onChange={(event) => {
                                            handleFileUpload(event)
                                        }}
                                    />
                                    <FileUploadIcon/>
                                    <Typography
                                        fontSize={14}
                                        sx={{
                                            ml: 1,
                                        }}
                                    >
                                        PDF hochladen
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        mb: 4,
                                        maxHeight: maxHeight,
                                        overflowY: 'auto',
                                    }}
                                >
                                    {pdfSources.map((source, index) => (
                                        <Fragment>
                                            <Box key={index} display="flex" alignItems="center"
                                                 justifyContent="space-between" height="42px" borderRadius={2} pl={2}
                                                 pr={1} bgcolor={"white.main"} width="100%">
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    style={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <Typography fontSize={14}
                                                                color={source.state === 'ready' ? 'text.primary' : 'text.fifth'}>
                                                        {source.name}
                                                    </Typography>
                                                </Box>
                                                <IconButton onClick={() => handleSourceDelete(source)} size="small">
                                                    <DeleteForeverOutlinedIcon color={'primary'}/>
                                                </IconButton>
                                            </Box>
                                            <Box mb={2} mt={-1}>
                                                {source.state === 'processing' &&
                                                    <LinearProgress style={{width: '100%'}}/>
                                                }
                                            </Box>
                                        </Fragment>
                                    ))}
                                    {pdfSources.length === 0 &&
                                        <Typography textAlign='center'>Noch keine PDF hinzugefügt</Typography>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box
                sx={{
                    height: '10%',
                    width: '100%',
                    borderTop: '1px solid #848484',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button
                    onClick={() => setSelectedItem("Q&A")}
                    style={{
                        fontSize: 14,
                    }}
                    variant="outlined"
                    sx={{
                        mr: 2,
                        width: 120
                    }}
                >
                    Abbrechen
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={() => Object.keys(knowledgebaseToEdit).length === 0 ? onFinalClick(users, sources, title, description) : onFinalClick(newUsers, deletedUsers, deletedSources, newSources, title, description)}
                    style={{
                        fontSize: 14
                    }}
                    variant="contained"
                    sx={{
                        ml: 2,
                        width: 120
                    }}
                >
                    {Object.keys(knowledgebaseToEdit).length === 0 ? "Anlegen" : "Speichern"}
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default KnowledgebaseForm;





