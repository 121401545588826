import {createContext, useEffect, useState} from "react";
import {decodeToken } from "react-jwt";

export const AuthContext = createContext({
    token: '',
    refreshToken: '',
    time: null,
    setRefreshToken: () => null,
    setToken: () => null,
    isAuthenticated: () => null,
    setAuthentication: () => null,
    logout: () => null,
    email: null,
    setEmail: () => null,
    userRole: [],
    setUserRole: () => null,
    getEmail: () => null,

});

export const AuthProvider = ({children}) => {
    const [authenticated, setAuthentication] = useState(false)
    const [token, setToken] = useState('')
    const [refreshToken, setRefreshToken] = useState('')
    const [email, setEmail] = useState(null);
    const [userRole, setUserRole] = useState([])

    useEffect(() => {
        if (localStorage.getItem('token') && localStorage.getItem('expirationDate') && localStorage.getItem('refreshToken')) {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate > new Date()) {
                console.debug('Token found');
                setAuthentication(true);
                setToken(localStorage.getItem('token'));
                setRefreshToken(localStorage.getItem('refreshToken'))
                const storedToken = localStorage.getItem('token');
                const decodedToken = decodeToken(storedToken);
                setUserRole(decodedToken.realm_access.roles);
                setEmail(decodedToken.email);
            } else {
                console.debug("Token expired");
                logoutHandler();
            }
        }

    },[]);

    useEffect(() => {
        let timerID = localStorage.getItem('timerID');
        if (authenticated) {
            if (timerID) clearTimeout(parseInt(timerID));

            timerID = setTimeout(() => {
                console.log(`Timeout reached at ${new Date()}`);
                logoutHandler();
            }, 15 * 60 * 1000);
            console.log(`Timeout set at ${new Date()} with ID ${timerID}`);
            localStorage.setItem('timerID', timerID.toString());
        } else {
            if (timerID) clearTimeout(parseInt(timerID));
        }

    }, [token]);

    function isAuthenticatedHandler() {
        return authenticated;
    }

    function setTokenHandler(token, refreshToken) {
        localStorage.setItem('token', token); // Store the token as a JSON string
        localStorage.setItem('refreshToken', refreshToken)
        const expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + 15);
        localStorage.setItem('expirationDate', expirationDate);
        const decodedToken = decodeToken(token);
        setUserRole(decodedToken.realm_access.roles)
        setEmail(decodedToken.email);
        setToken(token);
        setRefreshToken(refreshToken)
    }

    function logoutHandler() {
        console.log('Logout');
        localStorage.removeItem('timerID');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('expirationDate');
        setAuthentication(false);
        setEmail('')
        setToken('');
    }

    function getEmail() {
        return email
    }

    const value = {
        isAuthenticated: isAuthenticatedHandler,
        setAuthentication,
        token,
        setToken: setTokenHandler,
        logout: logoutHandler,
        email,
        setEmail,
        refreshToken,
        userRole,
        getEmail: getEmail
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}
